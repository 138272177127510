import React from "react";
import InfoFooter from "./component/InfoFooter";

export default function Privacy() {
  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <h3>隐私保护声明</h3>
      <p>
        感谢您对我们绿城超市的关注及相信。保护您的隐私对我们来说非常重要。通过下面的内容您可以了解到关于您的隐私数据的详细情况。
        <br />
        一、我们收集信息的范围
        <br />
        - 姓名
        <br />
        - 地址
        <br />
        - 电子邮箱
        <br />
        - 电话号码
        <br />
        - 银行账号
        <br />
        原则上，我们仅从您那里收集和使用网站的正常运作以及我们的内容和服务所必需的个人信息，例如，您在我们的网站上注册或登录已有的帐户，或者订购产品。只有在您同意的情况下，我们才会定期收集和使用您的个人信息。
        例外情况发生于当实际情况不允许而无法事先同意且法律允许处理数据的情况。本隐私保护政策仅适用于我们的网站。如果您通过我们页面上的链接跳转到其他页面，请您了解相应网站的数据收集处理方式。
        <br />
        二、您的个人信息的安全性
        <br />
        保护您的个人信息安全是我们的首要任务。因此，我们将采取技术和组织方面的措施保护您存储的数据，以有效防止信息丢失或第三方滥用。尤其是我们负责处理个人信息的员工都有维护信息保密性的义务，并且也一定会遵守。为了保护您的个人信息，它们将以加密形式传输；例如，在您的浏览器与服务器之间交换数据时受
        SSL（Secure Socket
        Layer）协议加密保护。这项协议的存在可以通过SSL连接期间您的浏览器显示的锁符号来识别。为了确保对数据的长期保护，我们会定期审查技术安全措施，并在必要时使其升级到最新技术水平。
        <br />
        三、我们收集您的个人信息的目的
        <br />
        我们收集、处理并使用您的个人信息出于以下目的：
        <br />
        - 建立以及完成合同关系
        <br />
        - 信息推送
        <br />
        - 为您提供安全保障
        <br />
        - 售后服务以及支持
        <br />
        四、关于Cookies
        <br />
        （1）目的
        <br />
        本网站将使用技术方面所需的Cookies。这些是存储在因特网浏览器中或由因特网浏览器存储在计算机上的小文本文件。这些Cookies可以实现如将多件产品加入购物车这类功能。
        <br />
        （2）法律依据
        <br />
        收集此项内容的法律依据是《数据保护条例》第六条第一款f项
        <br />
        （3）存储期限
        <br />
        关闭浏览器时，通常会删除技术方面必需的Cookies。长期存储的Cookies的寿命从几分钟到几年不等。
        <br />
        <br />
        五、您的权利
        <br />
        在具体条件满足的情况下，您可以针对我们处理的个人数据主张以下的权利：
        <br />
        <br />
        访问权
        <br />
        您有权要求我们提供我们处理的您的个人数据的相关信息。
        <br />
        更正权
        <br />
        您有权要求更正不准确的个人数据或者补全不完整的个人数据。
        <br />
        <br />
        删除权
        <br />
        您有权要求删除关于您的个人数据，特别是在下列理由之一适用的情况下：
        <br />
        • 您的个人数据不再为收集或处理目的所需。
        <br />
        • 您撤回数据处理的同意。
        <br />
        • 您已申明反对关于您数据的处理活动。
        <br />
        • 您的数据已被非法处理或处理超出约定范围。
        <br />
        如果与控制人的合法利益有冲突，删除权将不适用，比如：
        <br />
        • 个人数据对于法律要求的建立、行使或辩护是必要的。
        <br />
        • 因为法律需要数据被保留以致删除是被禁止的。
        <br />
        如果数据不可被删除，您可能会有权利限制数据处理（阅读以下）：
        <br />
        限制处理权
        <br />
        您有权对我们处理您的个人数据进行限制，只要:
        <br />
        • 个人数据的准确性被您质疑，因此正在被我们验证。
        <br />
        • 该处理违法，您反对个人数据的删除但同意对数据使用进行限制。
        <br />
        •
        个人数据不再为我方处理目的所需，但是根据您的要求为建立、行使或辩护法律要求所需。
        <br />
        •
        您已经反对处理您的个人数据，但是关于我们的合法理由是否高于您的理由的验证正在处理。
        <br />
        可移植数据权
        <br />
        您有权以结构化、通用和机器可读的格式收到关于您向我们提供的个人数据；您也有权使个人数据直接传输到其他控制者而不受我们的限制，前提是处理基于同意或合同且我们以自动化方式进行处理。
        <br />
        反对权
        <br />
        如果处理您的个人数据是基于同意，您有权随时撤回您的同意。
        <br />
        4. 删除数据的标准期间
        <br />
        没有法定保存期限的数据一旦不再为处理目的所需应当被尽快删除和/或销毁。不同保存期限适用于不同类型的个人数据。最后，保存期限也可基于法定时效期间。
        <br />
        六、数据保留、帐户停用和删除
        <br />
        我们存储数据，直至我们不再需要这些数据来提供服务和绿城相关产品，或直至您的帐户被删除，以较早发生者为准。这需要根据具体情况来决定，例如数据的性质、收集和处理数据的原因以及相关的法律或运营留存需求等。例如，您在unioncity搜索内容后，可以随时在自己的搜索历史记录中访问和删除该次查询，但该搜索的日志将在
        6
        个月后删除。如果您提交政府签发的身份证件的副本用于帐户验证，我们将在审核帐户后
        30
        天删除该副本，除非另有说明。详细了解与删除您所分享的内容和通过社交插件获得的
        Cookie 数据有关的信息。
        <br />
        当您删除自己的帐户后，我们会删除您搜索的内容，之后您将无法恢复这些已删除的信息。如果您不想删除自己的帐户，但想暂时停止使用我们的产品，则可以停用帐户。在任何时候您如需删除帐户，可访问
        进行删除。
        <br />
        七、本隐私保护声明的变更
        <br />
        为了确保我们的隐私政策始终符合当前的法律要求，我们保留随时进行更改的权利。这也适用于由于新的或重新制定的优惠或服务而必须调整隐私政策的情况。
        <br />
      </p>
      <InfoFooter />
    </div>
  );
}
