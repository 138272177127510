import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { get_DNS } from "../../../api";
import { Link } from "react-router-dom";
const useStyle = makeStyles({
  linkList: {
    width: 500,
    height: 70,
    borderTop: "1.5px solid #1d1d38",
    color: "#1d1d38",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Montserat",
    "& a": {
      color: "#1d1d38",
      "&::visited": {
        color: "#1d1d38",
      },
    },
  },
});
export default function Footer() {
  const classes = useStyle();
  return (
    <>
      <div
        style={{
          width: "100%",
          height: 450,
        }}
      ></div>
      <div
        style={{
          width: "100%",
          height: 250,
          position: "absolute",
          bottom: 0,
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#c0e57b80",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/icon/logo.png"}
            alt=""
            style={{ height: 100, width: 200, objectFit: "scale-down" }}
          />
          <div className={classes.linkList}>
            <Link to={"/aboutus"} onClick={() => window.scrollTo(0, 0)}>
              关于我们
            </Link>
            <Link to={"/FAQ"} onClick={() => window.scrollTo(0, 0)}>
              常见问题
            </Link>
            <Link to={"/privacy"} onClick={() => window.scrollTo(0, 0)}>
              隐私保护声明
            </Link>
            <Link to={"/terms"} onClick={() => window.scrollTo(0, 0)}>
              服务条款
            </Link>
            <Link to={"/shippingInfo"} onClick={() => window.scrollTo(0, 0)}>
              运费说明
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
