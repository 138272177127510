import React from "react";
import InfoFooter from "./component/InfoFooter";

export default function FAQ() {
  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <h2 style={{ textAlign: "center" }}>关于我们</h2>
      <div>
        一. 在绿城超市我怎样找到需要的产品？
        <br />
        <br />
        1.首先,您可以在网站顶部搜索框,使用关键词寻找，其次，可以在分类列表选择查看该分类里的所有产品
        <br />
        <br />
        2.如果您没有找到所需商品，您也可以咨询我们中文客服了解相关产品信息。由于商品数量很多，或许部分商品我们还未完成上架，您可以联系我们的客服咨询.
        <br />
        <br />
        3.我们也会聆听消费者的意见建议，第一时间获得更多中国以及全球优秀商品，为海外华人提供更多物美价廉的产品。
        <br />
        <br />
        二. 忘记登录密码怎么办？
        <br />
        <br />
        如果您忘记密码，在登陆界面点击
        "忘记密码"后输入您的注册手机号，系统会自动发送重置密码的信息到您手机，如无法收到短信，请联系我们客服处理。
        <br />
        <br />
        三. 支付
        <br />
        <br />
        在绿城网站线上超市可以选择网上支付（PAYPAL或者STRIPE使用银行卡支付），同城配送可选择货到付款。
        <br />
        在绿城小程序线上超市可以选择微信支付，同城配送可选择货到付款。
        <br />
        <br />
        新鲜称重产品，如酸奶，鲜奶，奶酪，火腿，果蔬，肉类，海鲜等产品无法进行快递运输，请谅解。
        <br />
        <br />
        四. 有的商品图片和我自己之前购买的包装不一样，怎么回事？
        <br />
        <br />
        由于部分厂家会不定期更换产品包装或产品批次不同以及色差原因导致产品包装略有不同
        <br />
        部分网页图片没有及时更换，所以您会看到图片不一样。
        <br />
        具体产品包装请以实际收到为准。并请反馈给我们客服，以便我们及时核对更新。
        <br />
        <br />
        五. 绿城超市促销活动怎么得知，以及具体产品信息
        <br />
        <br />
        请关注我们微信公众号，添加微信客服号（客服微信号：GREENCITYPISTOIESE）
        <br />
        关注小程序信息，可以得到最新促销信息，以及优惠券发放。
        <br />
        <br />
        六. 可以使用人民币支付订单吗？
        <br />
        <br />
        我们与中国境内的微信平台合作，您下单即可使用人民币支付订单
        <br />
        汇率会按照产品欧元价和当日微信汇率自动换算。目前只在小程序里可以使用微信支付
        <br />
        后期我们会为顾客提供更多途径的支付方式。
        <br />
        <br />
        七. 下单后发现需要更改商品或者收件人信息，怎么办？
        <br />
        <br />
        确定下单后将无法修改订单内容，如未确定下单，您可直接选择继续购物。
        <br />
        如订单已选择货到付款或网上支付，订单将无法更改内容。若您需要更改订单的收件人信息
        <br />
        请联系我们客服（客服微信号：GREENCITYPISTOIESE）
        <br />
        并提供订单编号，客服会为您确定订单并为您更改您的订单收件人信息
        <br />
        如订单已备货、打包、发货，订单将无法取消及更改。
        <br />
        如订单还未处理，客服将为您手动更改订单。
        <br />
        <br />
        八. 下单多久后能够发货，多久收到？
        <br />
        <br />
        同城配送，您在截单前下单，如无特殊情况，会当日内送达，如出现订购的商品缺货，若缺货产品数量不多，我们将不会打扰您，若缺货产品较多，客服会及时联系您进行沟通。
        <br />
        <br />
        意大利境内客人，您在正常工作日截单前下单，如无特殊情况，快递货物将会在当日下午，或是次日上午由快递服务商带走并发送，2-4天内到达，疫情期间时间难以把控。
        <br />
        <br />
        签收时请查看货物打包箱是否有任何破损，在开箱前请注意查看货物是否被拆封，若发现被查封，请在开箱前录制清晰视频发送给客服，检查是否缺少货物。
        <br />
        我们为您的快递提供基础保险，若遭到货物遗失，大量损坏，可获得每公斤6€的赔付。
        <br />
        <br />
        九. 如何查询我的订单状态？
        <br />
        <br />
        同城配送的客人，您可以登录我们绿城超市账户，点击页面右上角“我的订单”，进入全部订单，将显示您的订单编号以及订单状态
        <br />
        意大利全境的客人，如有需要可以联系客服，提供该订单单号，我们会向您提供快递单号
        <br />
        或者帮您查看快递详情
        <br />
        <br />
        十. 我购买的中国商品是否需要额外交税？
        <br />
        <br />
        我们的商品经过正常商品进口入关销售流程
        <br />
        在网站上显示的价格是包含欧盟消费税后的最终到手价格，请放心购买。
        <br />
        <br />
        十一.收到包裹后，发现包裹有问题，怎么办？
        <br />
        <br />
        对于运输中的商品破损，存在质量问题的商品，过期商品，均无条件退换。
        <br />
        请及时联系客服，并提供订单编号，小票及受损，过保质期，错发的商品清晰图片发送给客服，我们将在核实后予以处理。
      </div>

      <InfoFooter />
    </div>
  );
}
