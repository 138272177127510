import React from "react";
import InfoFooter from "./component/InfoFooter";

export default function ShippingInfo() {
  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <p>
        同城配送运费为 8€
        <br />
        凡购物满50€ 3公里内免运费
        <br />
        满100€的订单6公里内免运费
        <br />
        <br />
        大袋米跟整提水不计算在免运份额内
        <br />
        同城配送的订单我们将于当日送达
        <br />
        <br />
        意大利境内快递 满100€包邮
        <br />
        如未达到包邮标准，快递费用为15€
        <br />
        快递将会在当日下午或次日上午（取决您下单的时间）
        <br />
        由快递服务商带走发送
        <br />
        新鲜称重产品，如酸奶，鲜奶，奶酪，火腿，果蔬，肉类，海鲜等产品无法进行快递运输，请谅解。
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        感谢您选择绿城超市
        <br />
        我们将竭尽全力为您打造生活的仪式感，希望您全方位感受到高品质的生活方式
      </p>
      <InfoFooter />
    </div>
  );
}
