import React from "react";

export default function InfoFooter() {
  return (
    <div
      style={{
        width: "90%",
        paddingTop: 30,
        margin: "auto",
        borderTop: "1px solid",
        fontSize: "1.5em",
        marginTop: "50px",
      }}
    >
      最后修订日期：2022 年 05月 16日
    </div>
  );
}
