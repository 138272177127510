import React from "react";

export default function Term() {
  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <h3>服务条款</h3>
      <p>
        欢迎访问绿城超市UNIONCITYITALY.COM（以下简称“本网站”）。本网站的服务由GREEN
        CITY S.R.L.以下简称“我们”向您提供。
        <br />
        <br />
        一、有效范围
        <br />
        <br />
        以下条款和条件适用于消费者和企业主在本网站的所有订单。
        <br />
        消费者是指既不以商业目的也不是以个体经营为目的达成合法交易的自然人。
        <br />
        企业主是自然人或法人或合法企业，在进行合法交易的过程中从事商业或其个人职业活动。
        <br />
        <br />
        二、配送条件
        <br />
        <br />
        除了规定的产品价格外，可能还会产生运输费用。有关运输费用的更多详细信息，请参阅相关内容。
        <br />
        配送将按下单时有效的配送方案送货至买方指定的送货地址。
        <br />
        客户有义务完全正确地提供此地址。
        <br />
        <br />
        三、订单处理
        <br />
        <br />
        在点击确认下单前，您可以继续购物，更改订单内容以及收件人联系方式地址等…
        <br />
        一旦确认下单后，将无法更改订单内容信息
        <br />
        <br />
        四、所有权保留
        <br />
        <br />
        货物在全额付款之前一直归我们所有。
        <br />
        <br />
        五、运输损坏
        <br />
        <br />
        对于消费者适用以下内容：如果交付的货物有明显的运输损坏
        <br />
        请及时联系客服，并提供订单号，快递单号，小票及货物清晰照片，在核实后会予以处理。
        <br />
        我们为您的快递提供基础保险，若遭到货物遗失，大量损坏，可获得每公斤6€的赔付。
        <br />
        <br />
        六、投诉程序
        <br />
        <br />
        如果您对产品或我们服务的任何部分不满意，您可以将您的投诉以文字形式发送给客服
        <br />
        我们将努力有效地解决您的投诉并根据情况采取我们认为必要的行动
        <br />
        <br />
        七、质量保证
        <br />
        <br />
        我们按照顾客确认的订单并参考产品名称和订购数量发货。
        <br />
        如果超过保质期，商品质量问题以及与所订购的产品完全不一致，则顾客有权要求退换。
        <br />
        客户有义务在收到货物后立即检查交付的货物，以确保符合订单要求并且无缺陷。
        <br />
        其他情况下卖方仅对已担保商品、蓄意行为和重大过失负责。
        <br />
        <br />
        八、知识产权
        <br />
        <br />
        本网站的版权与我们相对应，因此您无权使用绿城超市的商标、标识和照片。
        <br />
        未经绿城超市书面许可，禁止使用本网站数据。与个别产品相关的知识产权将仍然是这些权利所属者的财产。
        <br />
        <br />
        九、免责声明
        <br />
        <br />
        本网站的内容是经过精心审查后生成的。但是对于内容的准确性，完整性和时效性，我们不作任何担保。
        <br />
        最终解释权归绿城超市所有
        <br />
        <br />
        十、禁止18岁以下购买含酒精类商品
        <br />
        <br />
        根据意大利法律，未满18岁的未成年禁止购买酒精类产品
      </p>
    </div>
  );
}
