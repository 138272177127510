import React from "react";
import pic1 from "../../component/icon/2_magic 1.png";
import pic2 from "../../component/icon/2_magic 2.png";
import pic3 from "../../component/icon/3_magic 1.png";
import pic4 from "../../component/icon/5_magic 1.png";
import InfoFooter from "./component/InfoFooter";
const imgStyle = {
  width: 600,
  height: 300,
  objectFit: "cover",
  display: "block",
  margin: "50px auto",
};
export default function AboutUs() {
  return (
    <div style={{ textAlign: "center" }}>
      <h2>关于我们</h2>
      <p>
        绿城超市是一家为当地华人与本地人提供优质购物服务的超市
        <br />
        我们的商品从欧洲，亚洲以及世界各地精心挑选
        <br />
        更有新鲜蔬菜水果，优质的肉类海鲜，店内超万种产品供客选择。
        <br />
        绿城商场斥巨资为各店铺装修，以更整洁、更美观、更舒适、更新鲜的形象。
        <br />
        为广大顾客提供更加方便，优质，舒适的一站式购物体验。
        <br />
        绿城超市秉承着以人为本，诚实守信的企业理念
        <br />
        以及真诚合作的伙伴关系
        <br />
        用最好的服务将最优质的商品以最低的价格提供给顾客。
        <br />
        快乐不是花的少，而是花的少买的多。
        <br /> 绿城凭借专业的队伍，严谨的管理
        <br />
        先进的设备，致力于打造更好，更便民的超市
        <br />
        为华人同胞提供更好的购物环境而不断努力。
        <br />
        绿城商场采用先进的现代企业经营理念和管理模式
        <br />
        以“统一采购，统一经营，保质保真，天天平价”等经营特色
        <br />
        以服务为根本，以规模促效益，以管理创一流
        <br />
        在商品的品种，档次以及购物环境上保持竞争优势
        <br /> 保证让顾客100%满意的标准。
        <br />
        以诚信为首，用“团结、拼搏、开拓、奉献”的企业精神
        <br />
        优良周到的服务态度，丰富优质的商品来满足顾客的生活所需 <br />
        <br />
        • 经营特色：品种齐全，价格实惠，服务优良，一站购齐。 <br />
        • 经营宗旨：优质、新鲜、方便、实惠。
        <br /> • 经营方针：专业服务，信誉第一，求新求变，持续经营。 <br />
        •
        企业精神：人尽其才，货畅其流，诚实做人，用心做事，永远真诚服务，每天前进一步。
        <br />
        • 做事准则：兢兢业业，恪尽职守。
        <br /> • 行为准则：可持续性成本优势。
      </p>
      <img src={pic1} alt="" style={imgStyle} />
      <p>
        绿城超市总店 <br />
        位于普拉托华人街附近的繁华街道 VIA G.BECAGLI 1/E <br />
        占地面积2000㎡ 地下停车场面积2000㎡。
      </p>
      <img src={pic2} alt="" style={imgStyle} />
      <p>
        绿城超市2号分店
        <br />
        位于普拉托中国街VIA PISTOIESE 199
        <br />
        占地面积3200㎡ 停车场面积1500㎡
        <br />
        在二楼还有电器城，婚礼城
      </p>
      <img src={pic3} alt="" style={imgStyle} />
      <p>
        绿城超市3号分店 <br />
        位于OSMANNORO 3600地区 <br />
        VIA PRATESE 191 <br />
        占地面积2000㎡ 并提供停车场
      </p>
      <img src={pic4} alt="" style={imgStyle} />
      <p>
        绿城超市4号分店
        <br />
        位于TAVOLA地区VIA PARONESE 131
        <br />
        占地面积600㎡ 并提供停车场
      </p>
      <InfoFooter />
    </div>
  );
}
