const global = {
  general: { goBack: "Indietro" },
  button: {
    expand: "Vedi di più",
    proceed: "Continua",
    confirm: "Conferma",
    delete: "Elimina",
    modify: "Modifica",
    logout: "Esci",
    checkout: "Check out",
  },
  payment: {
    payMethod: "Segli il metodo di pagamento",
    payMethod_online: "Paga online",
    payMethod_offline: "Paga alla consegna",
    payMethod_selected: "Metodo di pagamento",
    priceShipping: "Spesa di consegna",
    priceTot: "Totale",
  },
  prodTable: {
    prod: "Prodotto",
    quantity: "Quantità",
    prodUnitPrice: "Prezzo Unità",
    prodTotalPrice: "Prezzo Totale",
    totalPrice: "TOTALE",
  },
};
const it = {
  global: global,
  login: {
    login: "ACCEDI",
    register: "REGISTRATI",
    reset: "RESET",
    orThirdParty: "o accedi con",
    firstTime: "Prima volta qui?",
    hasAccount: "Hai già l’account?",
    forgetPwd: "hai dimenticato la tua password?",
    receive: "RICEVI",
    timer: "s",
    phoneAreaSel: "Prefisso",
    placeholders: {
      account: "Code/Email/Num. di telefono",
      region: "--scegli il paese--",
      pwd: "Password",
      pwdNew: "Nuova password",
      pwdOld: "Vecchia password",
      pwdConfirm: "Conferma password",
      verifCode: "Codice di verifica",
    },
    alert: {
      wrongCred: "Nome utente o Password errata",
    },
  },
  address: {
    shipTo: "Consegna a:",
    selectAddr: "Scegli il tuo indirizzo",
    enterAddr: "Inserisci il tuo indirizzo",
    curLocation: "La tua posizione attuale",
    getCurLocation: "Ottieni la posizione attuale",
    locate: "Localizzare",
    locateTooltip: "Acquisisci la tua posizione",
    savedAddr: "I miei indirizzi salvati",
    savedAddrError: "Non ci sono indirizzi salvati.",
    confirmAddr: "CONFERMA L'INDIRIZZO",
    addNewAddr: "Inserisci il nuovo indirizzo",
    newAddrDetails: "Scala/Porta/Indicazione ecc...",
    modal: {
      placeholder: {
        recipient: "Il nome del destinatario",
        recipientPhone: "Num. di telefono del destinatario",
      },
    },
  },
  cart: {
    title: "CARRELLO",
    modal: {
      shopLabel: "SHOP NO. ",
      emptyTitle: "CARRELLO VUOTO",
      emptyMsg: "Vai nei negozi a riempire i tuoi carrelli ora!",
      order: "ORDINARE",
    },
    page: {
      shipInfo: "DETTAGLIO CONSEGNA",
      modify: "MODIFICA",
      prodInfo: "DETTAGLIO SPESA",
      shipPrice: "SPESA CONSEGNA",
      confirm: "CONFERMA",
      continueShopping: "NON ADESSO, CONTINUA LO SHOPPING",
    },
    table: global.prodTable,
    alert: {
      title: "INFO INCOMPLETE",
      message: "Si prega di inserire le informazioni di consegna complete!",
      recipient: "Pregasi inserire il nome del recipiente",
      number: "Pregasi inserire un numero di telefono",
    },
  },
  order: {
    modal: {
      emptyTitle: "ORDINE VUOTO",
      emptyMsg: "现在就去商店下单吧!",
      allOrders: "Tutti gli ordini",
      orderOpen: "Vedere l'ordine",
      fillOrder: "Vai nei negozi a completare un ordine ora!",
    },
    page: {
      checkOut: "CASSA",
      orderInfo: "DETTAGLIO ORDINE",
      orderInfo_number: "Ordine num.",
      orderInfo_date: "Data",
      shipInfo: "Dettaglio consegna",
      prodInfo: "DETTAGLIO SPESA",
      shipPrice: global.payment.priceShipping,
      selPayment: global.payment.payMethod,
      payOnline: global.payment.payMethod_online,
      payOffline: global.payment.payMethod_offline,
      labelShop: "Negozio",
      shipDetail:
        "Standard di spedizione: - Spedizione gratuita entro 3 chilometri per acquisti superiori a 50€ - spedizione gratuita entro 6 chilometri per ordini superiori a 100€ Grandi sacchi di riso e confezioni d’acqua non sono inclusi nella spedizione gratuita (la consegna dei suddetti prodotti non verrà effettuata).Dopo aver effettuato l'ordine, per la zona vicina verrà consegnata entro 3-4 ore e per il resto territorio d’Italia entro 2 -4 giorni. Per la zona vicina, se decide la modalità di pagamento online e non soddisfa gli standard di spedizione gratuita, il cliente può scegliere di pagare 8 € (costo di spedizione) all'arrivo del pacco o di contattare il servizio clienti per pagarlo online. Se sei un cliente all’interno del territorio italiano, la spedizione gratuita è prevista per un acquisto pari o superiore a 100€, (i prodotti freschi e congelati non verranno consegnati per motivo del tempo di spedizione) Se non raggiunge lo standard di spedizione gratuita, le preghiamo di prendere il prodotto denominato ‘costo di spedizione’ pari a 15 €, nel caso del dimentico, si prega di contattare il servizio clienti tramite WeChat (WeChat: GREENCITYPISTOIESE) e per il pagamento delle spese di spedizione. Gli ordini confermati prima delle ore 19:00 verranno spediti entro la giornata e dopo le 19.00  verranno elaborati il giorno successivo",
    },
    table: global.prodTable,
    status: {
      canceled: "CANCELLATO",
      toPay: "DA PAGARE",
      inProgress: "IN PROCESSO",
      receiving: "In Ricezione",
      preparing: "In Preparazione",
      delivering: "In Consegna",
      completed: "COMPLETO",
    },
    alert: {
      title: "L'ordine è scaduto",
      message:
        "L'ordine è stato automaticamente annullato, si prega di riordinare",
    },
  },
  selfCenter: {
    welcome: "CIAO! ",
    selfCenter: "Profilo",
    logOut: "Esci",
    modify: "Modifica ",
    notYet: "Non c'è",
    name: "Nome",
    account: "Account",
    social: "Social",
    paymentMethod: "Metodo di pagamento",
    password: "Password",
    address: "Indirizzi",
    defaultAddressLabel: "Default",
    language: "Lingue",
    userCenter: "Centro utente",
    placeholders: {
      name: "Aggiungi il tuo nome",
      social: "Nessun social connesso",
      paymentMethod: "Nessun metodo di pagamento impostato",
      address: "Nessun indirizzo impostato",
    },
  },
  home: {
    welcome: "Bevenuti！",
    locals: "I nostri locali",
    slogan: {
      black: "Spesa mondiale\nconsegnata ",
      white: "a casa tua.",
    },
    adress: {
      tooltip: "Inserisci il tuo indirizzo per raggiungere i negozi:",
      finder: "Usa indirizzo attuale",
    },
  },
  shop: {
    banner: {
      0: "consegna a casa",
      1: "paga alla consegna",
      2: "prime",
    },
    sidebar: {
      discounted: "Scontati",
    },
  },
  prod: {
    price: "prezzo",
    details: "Caratteristiche del prodotto",
    suggestions: "Ti potrebbe interessare",
  },
  citta: {
    PRATO: "PRATO",
    FIRENZE: "FIRENZE",
    ROMA: "ROMA",
    MILANO: "MILANO",
    MONZA: "MONZA",
    AGRIGENTO: "AGRIGENTO",
    ALESSANDRIA: "ALESSANDRIA",
    ANCONA: "ANCONA",
    AOSTA: "AOSTA",
    "ASCOLI PICENO": "ASCOLI PICENO",
    "L'AQUILA": "L'AQUILA",
  },
  components: {
    search: {
      prod: "Cerca prodotti nel negozio",
    },
    button: {
      expand: "Vedi di più",
      proceed: "Continua",
      modify: "Modifica",
      logout: "Esci",
    },
    nav: {
      back: "Indietro",
      backShop: "Continua lo shopping",
    },
    alerts: {
      outOfRange: "Fuori dall'area di servizio",
      success: "Ok",
    },
    language: "Lingue",
    placeholders: {
      nameInput: "Aggiungi il tuo nome",
    },
  },
};

export default it;
